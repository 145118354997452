<template>
  <div class="vm-section vm-core d-flex align-center">
    <v-container class="text-center">
      <v-row class="justify-center">
        <v-col class="xs-12 sm-5 md-3">
          <h1>What is the Vuma Schools Project?</h1>
          <p>
            It’s an initiative we started in 2015 to connect schools to a 1
            Gigabit per second internet connection for free.
          </p>
          <p>
            Education is the backbone to a bright future for the leaders of
            tomorrow. That’s why we, together with our Internet Service Provider
            partners, are committed to providing every primary and high school
            we pass in our growing network of neighborhoods, with a free 1
            Gigabit per second internet connection.
          </p>
          <p>
            Since we began this initiative in 2015, we’ve successfully connected
            over 600 schools, providing over 450 000 learners and teachers with
            a fibre line at no cost, ensuring that they reap the benefits of
            free, world-class internet access.
          </p>
          <v-btn 
            @click="scroll" 
            rounded 
            outlined 
            class="pink-outline move-to"
            >Enquire now</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1990,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 2870,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>