<template>
  <div class="vm-section vm-fibre-process">
    <v-container class="text-center">
      <h2>{{ heading }}</h2>
      <v-row class="vm-process-blocks flex-parent">
        <v-col
          class="col-sm-4 flex-item"
          v-for="item in processFlowData"
          :key="item.id"
        >
          <div class="vm-b-img ">
            <img
              v-if="item.id === 1"
              src="@/assets/images/products/core/vuma-fibre-process-1.svg"
              :alt="item.title"
            />
            <img
              v-if="item.id === 2"
              src="@/assets/images/products/core/vuma-fibre-process-2.svg"
              :alt="item.title"
            />
            <img
              v-if="item.id === 3"
              src="@/assets/images/products/core/vuma-fibre-process-3.svg"
              :alt="item.title"
            />
          </div>
          <h4>{{ item.title }}</h4>
          <p>{{ item.description }}</p>
        </v-col>
      </v-row>
      <v-btn
        @click="scroll"
        data-section="vm-coverage-wrap"
        rounded
        outlined
        class="move-to"
      >
        {{ enquiryButton }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "CoreFibreProcess",
  data() {
    return {
      searched: false,
      heading: "How does your school qualify?",
      enquiryButton: "Enquire now",
      processFlowData: [
        {
          id: 1,
          icon: "@/assets/images/products/core/vuma-fibre-process-1.svg",
          title: "Be a school",
          description: "Your school needs to be a Primary or High School.",
        },
        {
          id: 2,
          icon: "@/assets/images/products/core/vuma-fibre-process-2.svg",
          title: "Register with the IEB or GDE",
          description:
            "Your school needs to be registered with the Independent Examinations Board or Department of Basic Education.",
        },
        {
          id: 3,
          icon: "@/assets/images/products/core/vuma-fibre-process-3.svg",
          title: "Covered by Vuma",
          description:
            "Your school needs to fall within the Vuma fibre network.",
        },
      ],
    };
  },
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 2050,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 3000,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vm-fibre-process {
  background-color: #ee008c;
  color: #fff;
  padding: 0;
  &.vm-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  h2 {
    margin-bottom: 3rem;
  }
  h4 {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 1rem;
    font-family: "Core Sans R 55", "Roboto", sans-serif !important;
    font-weight: inherit;
  }
  .vm-b-img {
    width: 150px;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  p {
    line-height: 1.2;
    padding: 0 1rem;
    margin-bottom: 0;
  }
  .theme--light.v-btn {
    color: #fff;
    margin-top: 3rem;
    &:before {
      opacity: 0;
    }
    &:hover {
      background-color: #fff;
      color: #ff008e;
    }
  }
  .vm-process-blocks {
    .v-btn {
      font-size: 18px !important;
    }
  }
}

.menu {
  float: left;
  width: 20%;
  text-align: center;
}

.menu a {
  background-color: #e5e5e5;
  padding: 8px;
  margin-top: 7px;
  display: block;
  width: 100%;
  color: black;
}

.main {
  float: left;
  width: 60%;
  padding: 0 20px;
}

.right {
  background-color: #e5e5e5;
  float: left;
  width: 20%;
  padding: 15px;
  margin-top: 7px;
  text-align: center;
}

@media only screen and (max-width: 620px) {
  .menu,
  .main,
  .right {
    width: 100%;
  }
}
</style>