<template>
  <div class="cont-pages">
    <CorporateSocialInvestmentBanner />
    <CorporateSocialInvestmentAbout />
    <CorporateSocialInvestmentTabs />
    <FibreProcess />

    <div class="reach-shop text-center">
      <v-container>
        <div v-if="!hasError">
          <h2>{{ mainHeading }}</h2>
          <p>{{ startText }}</p>
          <p>{{ middleText }}</p>
          <p>{{ endText }}</p>
          <UserForm :user="user" />
        </div>
        <div v-else>
          <p>
            Unfortunately we could not submit your request, please try again
            later.
          </p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CorporateSocialInvestmentBanner from "@/components/Products/CorporateSocialInvestment/Banner";
import CorporateSocialInvestmentAbout from "@/components/Products/CorporateSocialInvestment/About";
import CorporateSocialInvestmentTabs from "@/components/Products/CorporateSocialInvestment/Tabs";
import FibreProcess from "@/components/Products/CorporateSocialInvestment/FibreProcess";
import UserForm from "../../components/ContactForm/UserForm.vue";

export default {
  name: "CorporateSocialInvestment",
  data: () => ({
    mainHeading: "Get in touch",
    startText:
      "The Vuma Fibre Schools Project is laying a strong foundation by providing free, super-fast, unlimited connectivity to every registered primary and high school located along our fibre network route.",
    middleText:
      "We believe in creating an imagination nation, one where every learner is given the choice to experience more through their education, as it is something every child deserves.",
    endText:
      "If your school meets the requirements and you would like to find out more about getting your school connected, complete the form below",
    user: {
      formName: "CorporateSocialInvestment",
      firstName: "",
      lastName: "",
      schoolName: "",
      contactNumber: "",
      enquiryMessage: "",
      email: "",
    },
  }),
  components: {
    CorporateSocialInvestmentBanner,
    CorporateSocialInvestmentAbout,
    CorporateSocialInvestmentTabs,
    FibreProcess,
    UserForm,
  },
  computed: {
    hasError() {
      return this.$store.getters.hasError;
    },
  },
  mounted() {
    $("html").css("overflow", "auto");
    $(".vuma-menu, .menu-overlay").removeClass("menu-show");
  },
  methods: {
    scrollCoverage() {
      let target = "html, body";

      $(target)
        .stop()
        .animate(
          {
            scrollTop: $(target).offset().top - 50,
          },
          500,
          "swing"
        );
    },
  },
  metaInfo() {
    return {
      title: "Vuma Fibre Reach | Affordable And Reliable Prepaid Internet",
      meta: [
        {
          name: "description",
          content:
            "The Vuma Fibre Schools Project is laying a strong foundation by providing free, super-fast, unlimited connectivity to every registered primary and high school located along our fibre network route.",
        },
        {
          property: "og:title",
          content:
            "The Vuma Fibre Schools Project | Affordable And Reliable Prepaid Internet",
        },
        { property: "og:site_name", content: "Vumatel" },
        { property: "og:type", content: "website" },
      ],
    };
  },
};
</script>

<style lang="scss">
.reach-shop {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .container {
    max-width: 1000px;

    .text-center {
      max-width: 750px;
      margin: 0 auto 3rem;

      h2 {
        margin-bottom: 1.5rem;
      }
    }

    h2 {
      margin-bottom: 2rem;
    }
  }

  .v-card {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
    padding: 1.8rem;
    border-radius: 35px !important;
    height: auto;

    .display-vm {
      font-size: 44px !important;
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-weight: inherit;
      line-height: 0.7;
      letter-spacing: 0.0073529412em !important;
      color: #ee008c;
      margin-bottom: 1.5rem;
    }

    .v-card__text {
      padding: 1.5rem;
      text-align: center;

      p {
        font-size: 17px;
        line-height: 1;
        margin-bottom: 0.5rem;

        .v-icon {
          color: #ee008c;
          font-size: inherit !important;
        }
      }
    }

    .v-card__actions {
      padding: 0.35rem 1.5rem 1.5rem;
      justify-content: center;

      a {
        text-decoration: none;
        font-size: 16px !important;
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;
        color: #ee008c;
      }

      .order-button:hover {
        color: #fff;
      }
    }

    .reach-img {
      padding: 0.35rem 0;
      width: 100%;
      height: auto;

      &.reduce-width {
        img {
          max-width: 40%;
        }
      }
    }
  }
}
</style>