var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"high-speed"}},[_c('v-card',{staticClass:"vm-tabs-product"},[_c('v-tabs',{attrs:{"dark":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.activetab,on:{"click":function($event){_vm.activetab = tab.activetab}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),1),_c('v-tabs-items',{staticClass:"vm-core-tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabData),function(tab){return _c('v-tab-item',{key:tab.key},[(_vm.activetab === 1)?_c('div',{class:_vm.classProperty},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-5 col-md-5"},[(_vm.activetab === 1)?_c('h2',{staticClass:"text-white"},[_vm._v(" "+_vm._s(tab.title)+" ")]):_vm._e(),_c('p',{staticClass:"vm-tab-content text-white",staticStyle:{"width":"100%","max-width":"460px"}},[_vm._v(" "+_vm._s(tab.description)+" ")]),_c('v-btn',{staticClass:"enquire-btn move-to btn-white",attrs:{"data-section":"vm-coverage-wrap","rounded":"","outlined":""},on:{"click":_vm.scroll}},[_vm._v(" "+_vm._s(_vm.enquireButtonText)+" ")])],1)],1)],1),_c('div',{staticClass:"vm-tab-bg",style:({
              backgroundImage: ("" + (tab.backgroundImage)),
            })})],1):_vm._e(),(_vm.activetab === 2)?_c('div',{class:_vm.classProperty},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-5 col-md-5"},[_c('h2',{staticClass:"text-white"},[_vm._v(_vm._s(tab.title))]),_c('p',{staticClass:"vm-tab-content text-white",staticStyle:{"width":"100%","max-width":"460px"}},[_vm._v(" "+_vm._s(tab.description)+" ")]),_c('v-btn',{staticClass:"enquire-btn move-to btn-white",attrs:{"data-section":"vm-coverage-wrap","rounded":"","outlined":""},on:{"click":_vm.scroll}},[_vm._v(" "+_vm._s(_vm.enquireButtonText)+" ")])],1)],1)],1),_c('div',{staticClass:"vm-tab-bg",style:({
              backgroundImage: ("" + (tab.backgroundImage)),
            })})],1):_vm._e(),(_vm.activetab === 3)?_c('div',{class:_vm.classProperty},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-5 col-md-5"},[_c('h2',{staticClass:"text-white"},[_vm._v(_vm._s(tab.title))]),_c('p',{staticClass:"vm-tab-content text-white",staticStyle:{"width":"100%","max-width":"460px"}},[_vm._v(" "+_vm._s(tab.description)+" ")]),_c('v-btn',{staticClass:"enquire-btn move-to btn-white",attrs:{"data-section":"vm-coverage-wrap","rounded":"","outlined":""},on:{"click":_vm.scroll}},[_vm._v(" "+_vm._s(_vm.enquireButtonText)+" ")])],1)],1)],1),_c('div',{staticClass:"vm-tab-bg",style:({
              backgroundImage: ("" + (tab.backgroundImage)),
            })})],1):_vm._e(),(_vm.activetab === 4)?_c('div',{class:_vm.classProperty},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-5 col-md-5"},[_c('h2',{staticClass:"text-white"},[_vm._v(_vm._s(tab.title))]),_c('p',{staticClass:"vm-tab-content text-white",staticStyle:{"width":"100%","max-width":"460px"}},[_vm._v(" "+_vm._s(tab.description)+" ")]),_c('v-btn',{staticClass:"enquire-btn move-to btn-white",attrs:{"data-section":"vm-coverage-wrap","rounded":"","outlined":""},on:{"click":_vm.scroll}},[_vm._v(" "+_vm._s(_vm.enquireButtonText)+" ")])],1)],1)],1),_c('div',{staticClass:"vm-tab-bg",style:({
              backgroundImage: ("" + (tab.backgroundImage)),
            })})],1):_vm._e()])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }