<template>
  <div class="vm-banner vm-product-banner d-flex align-center">
    <div class="swiper-container" id="lines-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide d-flex align-center"
          v-for="(item, i) in slides"
          :key="i"
        >
          <div
            class="vm-banner-bg"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/images/products/CorporateSocialInvestment/banners/csi-page-banner.jpg') +
                ')',
            }"
          ></div>
          <v-container>
            <div class="header-wrap">
              <div class="bigger-header" v-html="item.title"></div>
              <div class="sub-header">{{ item.subHeader }}</div>
            </div>
            <v-btn
              @click="scroll"
              data-section="vm-coverage-wrap"
              rounded
              outlined
              dark
              class="move-to"
            >
              {{ enquiryButton }}
            </v-btn>
          </v-container>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enquiryButton: "Find out more",
      slides: [
        {
          title: "Connecting Schools to unlimited internet access.",
          subHeader:
            "Empowering learners and educators with access to digital learning tools.",
        },
      ],
    };
  },
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1220,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 2356,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vm-product-banner {
  .container .header-wrap .sub-header {
    max-width: 750px;
  }

  .swiper-container {
    width: 100%;
  }
}
</style>