<template>
  <div id="high-speed">
    <v-card class="vm-tabs-product">
      <v-tabs 
        v-model="tab" 
        dark 
        grow
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.activetab"
          v-on:click="activetab = tab.activetab"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="vm-core-tabs">
        <v-tab-item v-for="tab in tabData" :key="tab.key">
          <div v-if="activetab === 1" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 v-if="activetab === 1" class="text-white">
                    {{ tab.title }}
                  </h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
          <div v-if="activetab === 2" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
          <div v-if="activetab === 3" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
          <div v-if="activetab === 4" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      searched: false,
      activetab: 1,
      enquireButtonText: "Enquire now",
      classProperty: "vm-section vm-tab-items d-flex align-center",
      tabData: [
        {
          key: "tab-core-entertain",
          description:
            "Valuable insights, e-learning platforms and educational tools are only a click away. Teachers and learners can easily access information for research, projects and knowledge gathering.",
          title: "Access necessary online information",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/CorporateSocialInvestment/tabs/csi-page-tabs-image-1.jpg`) +
            ")",
        },
        {
          key: "tab-core-connect",
          description:
            "Utilize new technology in the classroom, transforming schools into exciting digital learning hubs. Furthermore, administrative activities can run efficiently, allowing for effortless communication between parents, teachers and learners.",
          title: "Enhanced learning experience",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/CorporateSocialInvestment/tabs/csi-page-tabs-image-2.jpg`) +
            ")",
        },
        {
          key: "tab-core-choose",
          description:
            "As we, together with our Internet Service Provider partners, offer free fibre and internet connectivity, allowing schools to save on the infrastructural and monthly costs associated with staying connected!",
          title: "Save on maintenance, infrastructure, hardware and IT costs",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/CorporateSocialInvestment/tabs/csi-page-tabs-image-3.jpg`) +
            ")",
        },
        {
          key: "tab-core-work",
          description:
            "Sharing information digitally provides a new way to connect in real time, as online discussions on classroom topics can be accessed by everyone - even those who are not present at school - keeping everyone connected and educated.",
          title: "Bridge communications gaps",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/CorporateSocialInvestment/tabs/csi-page-tabs-image-4.jpg`) +
            ")",
        },
      ],
      tabs: [
        {
          activetab: 1,
          title: "Inform",
        },
        {
          activetab: 2,
          title: "Learn",
        },
        {
          activetab: 3,
          title: "Save",
        },
        {
          activetab: 4,
          title: "Share",
        },
      ],
    };
  },
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 2050,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 3000,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vm-tabs-product {
  .text-white {
    color: #fff;
  }
  .theme--dark.v-tabs {
    & > .v-tabs-bar {
      background-color: #ee008c;
      height: 85px;
    }
    .v-tab {
      font-size: 25px;
      font-family: "Core Sans R 35", "Roboto", sans-serif !important;
      text-transform: none;
      letter-spacing: 0.1em;
      width: 20%;
      color: #fff !important;
      &.v-tab--active {
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;
        &::before {
          opacity: 0.24 !important;
        }
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #ee008c;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }
      &:hover::before {
        opacity: 0.24;
      }
    }
    .v-tabs-slider-wrapper {
      display: none;
    }
  }
  .vm-tab-items {
    color: #000;

    .vm-tab-bg {
      &:before {
        display: none;
      }
    }
  }

  .v-btn.btn-white {
    color: #fff;
    transition: all 0.2s ease-in;
    &:hover {
      background-color: #ff008e;
      border-color: #ff008e;
    }
  }
}

@media only screen and (max-width: 600px) {
  .enquire-btn,
  .enquire-btn span.v-btn__content {
    border-color: #ee008c;
    color: #ee008c;
  }
}
</style>